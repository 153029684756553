/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
#content {
  margin-top: 10%;
}
.desk {
  min-width: 460px;
  width: 90%;
  max-width: 44em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.area .part,
.area > .grid table {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area .tiny {
  width: 40%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 10%;
  margin-left: 10%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 80%;
}
.area > .slim .tiny {
  width: 80%;
}
.wide.form .tile .name,
.wide a.capt {
  float: left;
  width: 33%;
}
.wide.form .tile .chop,
.wide.form .tile textarea,
.wide.form .tile input.text,
.wide.form .tile .ctrl,
.wide input.capt {
  float: right;
  width: 63%;
}
.wide.resp .swap > table {
  float: left;
  width: 70% !important;
}
.wide.resp > .foot > .scan > .ctrl {
  margin-top: 0;
}
.wide p.topi {
  float: left;
  width: 60%;
}
.wide .resp > .link {
  text-align: right;
}
.wide .resp > .link > .open {
  float: left;
  width: 60%;
  text-align: left;
}
.wide .tall.auth.tile .text,
.wide .tall.auth.tile .password {
  width: 63%;
}
.wide .tall.payp.tile,
.wide .tall.payp.tile .papc {
  position: static;
}
.wide .tall.payp.tile .papc {
  margin: 0;
  padding: 0;
}
.wide .tall.payp.tile input.numb {
  position: static;
  float: left;
}
.wide .tall.payp.tile .name {
  float: left;
  width: 60%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 10% !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
#disp a.capt {
  width: 50%;
}
#disp input.capt {
  float: right;
  width: 48%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
#disp.cb-photoeditor {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
#disp.cb-lightbox-code {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
#disp.shop,
#disp.cb-shoppingcart {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */